/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import BlockText from '../BlockText';

const Contacts = ({ classes }) => {
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        about {
          address
          phone {
            name
            url
          }
          email {
            name
            url
          }
          designer {
            title
            link {
              name
              url
            }
          }
          copy_right {
            title
            content
          }
        }
      }
    }
  `);
  const { address, phone, email, designer, copy_right: copyRight } = dataJson.about;
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className={classes.container}>
      <BlockText content={address} />
      <BlockText className={classes.phone}>
        <p>
          Telefon{' '}
          <a href={phone.url} data-rel="external">
            {phone.name}
          </a>
        </p>
        <a href={email.url} data-rel="external">
          {email.name}
        </a>
      </BlockText>
      <div className={classes.designer}>
        {designer.title.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
        <a href={designer.link.url} data-rel="external" rel="noopener noreferrer" target="_blank">
          {designer.link.name}
        </a>
      </div>
      <BlockText>
        <p>{copyRight.title}</p>
        <p>
          {`© Copyright ${currentYear}.`} {copyRight.content}
        </p>
      </BlockText>
    </div>
  );
};

Contacts.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Contacts;
