export default ({ text, spacing }) => {
  const { fontSize, lineHeight } = text.main;

  return {
    container: {
      '@media (min-width: 1100px) and (max-width: 1600px)': {
        width: spacing * 60,
      },
      '& a': {
        ...text.link,
      },
    },
    phone: {
      marginBottom: 2 * fontSize * lineHeight,
    },
    designer: {
      marginBottom: fontSize * lineHeight,
      '&>p': {
        margin: 0,
      },
    },
  };
};
